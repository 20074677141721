import * as React from "react"
import { JSONData, allSites, computeStatistics } from "../data/msu_data"
import { Link } from "gatsby"
// styles

var sites = allSites()
console.log(sites)

const IndexPage = ({ data }) => {
  return (
    <main>
      <title>All Sites</title>
      <h1>All Sites</h1>
      <ul>
        {allSites().map(row => (
          <li key={row.id}><Link to={`/site/${row.slug}`}> {row.company}</Link></li>
        ))}
      </ul>
      </main> )
}

export default IndexPage
